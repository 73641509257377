import React, { useEffect, useState, useReducer } from "react"
import { graphql, PageProps } from "gatsby"
import getSearchParam from "@ecom/ui/utils/getSearchParam"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/Pkw"
import { Banner as RoundedDynamicBanner } from "../components/Banners/DynamicBanners/RoundedDynamicBanner"
import { InstallmentExample } from "../components/InstallmentExample"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { CardWithMaxBenefits } from "../components/CardWithMaxBenefits"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefitsRedisign } from "../components/MorBenefitsRedisgn"
import { Partners } from "../components/PartnersNew"
import { Reviews } from "../components/Reviews"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"

import { getPageData } from "../helpers/getPageData"

import { BannerText, getDynamicBannerText } from "../helpers/getDynamicBannerText"
import { getDynamicBannerUrl } from "../helpers/getDynamicBannerUrl"

import { PageData } from "../interfaces/pageProps"
import { sendScrollEventToDataLayer } from "../helpers/sendScrollEventToDataLayerPkw"
import { QUESTIONS_FOR_PKW } from "../components/Questions/helpers"

const titleForm = (
  <>
    Оформите карту «Халва» <br /> и получите подписку <br /> «Халва.Десятка» в подарок
  </>
)

const bannerTitle = `
  <span>Карта «Халва»</span>
  <br/>
  24 месяца без на всё у&nbsp;партнеров*
`

const bannerDescription = `
  — До 17% на остаток по карте
  <br />
  — Кэшбэк до 10%
  <br />— Обслуживание 0 ₽  
`

const subtextCalculator =
  "Вы не платите проценты, за вас это сделает магазин. Платеж делится на равные части каждый месяц. К тому же по подписке «Халва.Десятка» срок рассрочки увеличивается до 300 дней у каждого партнера!"

const IDS = ["formPkw", "textBottomCalculator", "partners", "questions", "footer"]

type ObserverType = {
  formPkw: boolean
  textBottomCalculator: boolean
  partners: boolean
  questions: boolean
  footer: boolean
}

function observerReducer(state: ObserverType, action: any) {
  if (IDS.includes(action.id)) {
    return { ...state, [action.id]: action.value }
  }
  throw new Error()
}

// Время для таймер(30мин)
const countDownTime = 1800000

const initialObserverState: ObserverType = {
  formPkw: false,
  textBottomCalculator: false,
  partners: false,
  questions: false,
  footer: false,
}

// TODO: Пока Новую длинную заявку надо показывать только на тесте + там он еще пройдет через АБ тест
// пока поставлю переменную только, придется поменять на тип того - globalThis.KameleeonExperiment(потом)

const longForm = process.env.GATSBY_ENV === "test"

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const [observerState, observerDispatch] = useReducer(observerReducer, initialObserverState)
  const [dynamicBannerTextObj, setDynamicBannerTextObj] = useState<BannerText>()
  const [dynamicBannerImagesObj, setDynamicBannerImagesObj] = useState<any>()

  const bannerTextUrl = getSearchParam("h")
  const bannerImageUrl = getSearchParam("bn")

  useEffect(() => {
    if (bannerImageUrl) {
      const bannerImagesData = getDynamicBannerUrl(data.allDynamicBanner, bannerImageUrl)
      if (bannerImagesData !== null) {
        setDynamicBannerImagesObj(bannerImagesData)
      }
    }
  }, [data.allDynamicBanner, bannerImageUrl])

  useEffect(() => {
    if (bannerTextUrl) {
      getDynamicBannerText(bannerTextUrl).then((result) => {
        setDynamicBannerTextObj(result)
      })
    }
  }, [bannerTextUrl])

  const callback = (entries: IntersectionObserverEntry[]) => {
    for (let index = 0; index < entries.length; index += 1) {
      const element = entries[index]
      const { id } = element.target
      observerDispatch({ id, value: element.isIntersecting })
    }
  }

  useEffect(() => {
    Object.keys(observerState).forEach((key) => {
      if (observerState[key as keyof typeof initialObserverState]) {
        sendScrollEventToDataLayer(key)
      }
    })
  }, [observerState])

  useEffect(() => {
    const isSupported =
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    let observer: IntersectionObserver

    if (isSupported) {
      observer = new IntersectionObserver(callback)

      IDS.forEach((id) => {
        const element = document.getElementById(id)
        if (element) {
          observer.observe(element)
        }
      })
    }

    return () => {
      if (isSupported) {
        IDS.forEach((id) => {
          const element = document.getElementById(id)
          if (element) {
            observer.unobserve(element)
          }
        })
      }
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint={false} hasCTA countDownTime={countDownTime} />
      {dynamicBannerImagesObj && bannerImageUrl ? (
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj.dymanicBannerDesk}
          mob={dynamicBannerImagesObj.dymanicBannerMob}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          orderNum="1"
        />
      ) : (
        <Banner description={bannerDescription} title={bannerTitle} variant="pkw" orderNum="1" />
      )}
      <CardWithMaxBenefits orderNum="2" />
      <FormPKW
        dataLayerName="shortPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        longForm={longForm}
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="3"
      />
      <HowInstallmentWorks variant="pkw" orderNum="4" />
      <NewCalculator
        titleLocationBottom
        subtext={subtextCalculator}
        additionalEventInDataLayer
        orderNum="5"
      />
      <InstallmentExample orderNum="6" />
      <MoreBenefitsRedisign additionalEventInDataLayer orderNum="7" />
      <Partners orderNum="8" />
      <Reviews additionalEventInDataLayer orderNum="9" />
      <Questions questionList={QUESTIONS_FOR_PKW} additionalEventInDataLayer orderNum="10" />
      <NewFooter ligal={ligal} orderNum="11" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/pkw/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
